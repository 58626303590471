<template>
  <Contact/>
</template>

<script>
import Contact from '@/components/Contact.vue';

export default {
  name: 'App',
  components: {
    Contact
  }
}
</script>
