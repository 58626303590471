<template>
  <Privacy/>
</template>

<script>
import Privacy from '@/components/Privacy.vue';

export default {
  name: 'App',
  components: {
    Privacy,
  }
}
</script>
