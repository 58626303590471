<template>
        <footer class="tm-bg-gray pt-5 pb-3 tm-text-gray tm-footer">
        <div class="container-fluid tm-container-small">
            <div class="row">
                <div class="col-lg-8 col-md-7 col-12 px-5 mb-3">
                    Copyright 2025 @KHQRmenu.com. All rights reserved.
                </div>
            </div>
        </div>
    </footer>
</template>