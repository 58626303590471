<template>
    <div class="container-fluid tm-mt-60">
        <div class="row tm-mb-50">
            <div class="col-lg-4 col-12 mb-5">
                <h2 class="tm-text-primary mb-5">Contact Page</h2>
                <form id="contact-form" action="" method="POST" class="tm-contact-form mx-auto">
                    <div class="form-group">
                        <input type="text" name="name" class="form-control rounded-0" placeholder="Name" required />
                    </div>
                    <div class="form-group">
                        <input type="email" name="email" class="form-control rounded-0" placeholder="Email" required />
                    </div>
                    <div class="form-group">
                        <textarea rows="8" name="message" class="form-control rounded-0" placeholder="Message" required></textarea>
                    </div>

                    <div class="form-group tm-text-right">
                        <button type="submit" class="btn btn-primary">Send</button>
                    </div>
                </form>                
            </div>
            <div class="col-lg-4 col-12 mb-5">
                <div class="tm-address-col">
                    <h2 class="tm-text-primary mb-5">Our Address</h2>
                    <p class="tm-mb-50">KHmenu.ClouD</p>
                    <address class="tm-text-gray tm-mb-50">
                        Lot 19-61, Russian Boulevard, Phum Teuk Thla, Sangkat Teuk Thla, Khan Sen Sok, Phnom Penh, Kingdom of Cambodia.
                    </address>
                    <ul class="tm-contacts">
                        <li>
                            <a href="mailto:themoondev@gmail.com" class="tm-text-gray">
                                <i class="fas fa-envelope"></i>
                                Email: themoondev@gmail.com
                            </a>
                        </li>
                        <li>
                            <a href="tel:068666420" class="tm-text-gray">
                                <i class="fas fa-phone"></i>
                                Tel: 068-666-420
                            </a>
                        </li>
                        <li>
                            <a href="https://khmenu.cloud" class="tm-text-gray">
                                <i class="fas fa-globe"></i>
                                URL: www.khmenu.cloud
                            </a>
                        </li>
                        <li>
                            <a href="https://fb.me/khmenu.cloud" class="tm-text-gray">
                                <i class="fas fa-globe"></i>
                                www.fb.me/khmenu.cloud
                            </a>
                        </li>
                    </ul>
                </div>                
            </div>
            <div class="col-lg-4 col-12">
                <h2 class="tm-text-primary mb-5">Our Location</h2>
                <!-- Map -->
                <div class="mapouter mb-4">
                    <div class="gmap-canvas">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7817.831121599908!2d104.86077370906588!3d11.557910880374187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310951c66d00795b%3A0xd057dee528c2bcab!2sMinistry+of+Commerce!5e0!3m2!1sen!2ssg!4v1449762618271" width="100%" height="520" frameborder="0" style="border:0" allowfullscreen></iframe>
                    </div>
                </div>               
            </div>
        </div>
        <!--
        <div class="row tm-mb-74 tm-people-row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                <img src="./../assets/img/user1.png" alt="Image" class="mb-6 img-fluid w-50">
                <h2 class="tm-text-primary mb-4">Som Sothea</h2>
                <h3 class="tm-text-secondary h5 mb-4">Developer</h3>
                <p class="mb-4">
                    Official of Ministry of Commerce, General Department of Trade Support Services, Department Export Import.
                </p>
                <ul class="tm-social pl-0 mb-0">
                    <li><a href="https://facebook.com"><i class="fab fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="https://linkedin.com"><i class="fab fa-linkedin"></i></a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                <img src="./../assets/img/user2.png" alt="Image" class="mb-6 img-fluid w-50">
                <h2 class="tm-text-primary mb-4">Heng Sombo</h2>
                <h3 class="tm-text-secondary h5 mb-4">Developer</h3>
                <p class="mb-4">
                    Official of Ministry of Commerce, General Department of Inspection-GDI.
                </p>
                <ul class="tm-social pl-0 mb-0">
                    <li><a href="https://facebook.com"><i class="fab fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="https://linkedin.com"><i class="fab fa-linkedin"></i></a></li>
                </ul>
            </div>
        </div>
        -->
    </div> <!-- container-fluid, tm-container-content -->
</template>