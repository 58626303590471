<template>
  <Login/>
</template>

<script>
import Login from '@/components/Login.vue';

export default {
  name: 'App',
  components: {
    Login
  }
}
</script>
