<template>
  <div class="main">
    <section>
      <!-- Store Details Card -->
    <div class="store-details card p-2 shadow-sm">
      <div class="store-banner">
      <img :src="getImageUrl(store?.storebanner)" v-if="store?.storebanner" class="img-fluid rounded">
      <p v-else>No banner available</p>
    </div>
      <div class="d-flex flex-column flex-md-row align-items-center p-2">
        <img :src="getImageUrl(store?.storelogo)" v-if="store?.storelogo" class="store-logo rounded-circle">
        <h2 class="mt-3 mt-md-0 ms-md-3">{{ store?.storename || "Store Details" }}</h2>
      </div>
      <p class="text-muted">{{ store?.storedescription || "No description available" }}</p>
      
      <div class="store-info mt-3">
        <div><i class="fas fa-map-marker-alt"></i> {{ store?.storeaddress || "No address available" }}</div>
        <div v-if="store?.storecontact">
          <a :href="`tel:${store.storecontact}`" class="text-decoration-none">
            <i class="fas fa-phone-alt"></i> Call: {{ store.storecontact }}
          </a>
        </div>
        <div v-if="store?.storetelegram">
          <a :href="store.storetelegram" target="_blank" class="text-decoration-none">
            <i class="fab fa-telegram-plane"></i> Telegram: {{ store.storetelegram }}
          </a>
        </div>
        <div class="text-center mb-4">
            <QRCode :value="appURL + `stores/` + storeurl" />
            <p>Scan QR to visit the store</p>
            <label @click="copyToClipboard" class="copy-label">Click to copy URL</label>
          </div>
      </div>
    </div>
    </section>
    <section>
      <!-- Sticky Category Navigation on Mobile -->
    <div class="category-nav">
      <button class="btn btn-primary btn-sm me-2" @click="fetchItems">All</button>
      <button v-for="category in categories" :key="category" class="btn btn-outline-primary btn-sm me-2" @click="fetchItemsByCategory(category)">
        {{ category }}
      </button>
    </div>
    </section> 
    <section>
      <div class="category"><h2 >Store's Items</h2></div>
        
        <div class="cards">
          <ItemCard v-for="(item, index) in items"
            :key="index"
            :id="item._id"
            :title="item.title"
            :description="item.description"
            :price="item.price"
            :storeid="item.storeid"  
            :itemimage="item.filename"
            :category="item.category">
          </ItemCard>
        </div>
      </section>
</div>

</template>

<script>
import { useRoute } from 'vue-router';
import ItemCard from '@/components/ItemCard.vue';
import axios from "axios";
import { getImageUrl } from '@/utils/imageHelper';
import QRCode from '@chenfengyuan/vue-qrcode';

const appURL = process.env.VUE_APP_DOMAIN_NAME;

export default {
  components: { ItemCard, QRCode },
  data() {
    const route = useRoute();
    return {
      appURL,
      items: [],
      categories: [],
      storeurl: route.params.storeurl || null,
      store: null,
      isQRCodeVisible: false
    };
  },
  async created() {
    await this.fetchStoreDetails();
  },
  methods: {
    getImageUrl,
    copyToClipboard() {
      // Get the current URL
      const currentUrl = window.location.href;
      
      // Create a temporary input to copy the URL
      const tempInput = document.createElement('input');
      tempInput.value = currentUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);

      // Optionally, show a message to the user
      alert('URL copied to clipboard!');
    },
    async fetchStoreDetails() {
      try {
        let storeResponse = null;

        if (this.storeurl) {
          try {
            storeResponse = await axios.get(`/v1/stores/url/${this.storeurl}`);
            this.store = storeResponse.data;
          } catch (urlError) {
            console.warn("Failed to fetch store by URL. Trying with store ID...");
          }
        }

        if (!this.store && this.storeid) {
          try {
            storeResponse = await axios.get(`/v1/stores/${this.storeid}`);
            this.store = storeResponse.data;
          } catch (idError) {
            console.error("Failed to fetch store by ID:", idError);
          }
        }

        if (this.store && this.store._id) {
          await this.fetchCategories();
          await this.fetchItems(); // Fetch all items initially
        } else {
          console.warn("No store data found.");
        }
      } catch (error) {
        console.error("Unexpected error in fetching store details:", error);
      }
    },

    async fetchCategories() {
      if (!this.store || !this.store._id) return;
      try {
        const response = await axios.get(`/v1/items/categories/${this.store._id}`);
        this.categories = response.data;
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    },

    async fetchItems() {
      if (!this.store || !this.store._id) return;
      try {
        const response = await axios.get(`/v1/items/store/${this.store._id}`);
        this.items = response.data;
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    },

    async fetchItemsByCategory(category) {
      if (!this.store || !this.store._id) return;
      try {
        const response = await axios.get(`/v1/items/store/${this.store._id}/category/${category}`);
        this.items = response.data;
      } catch (error) {
        console.error(`Failed to fetch items for category ${category}:`, error);
      }
    }
  }
};
</script>

<style>
/* Responsive Store Details Card */
.store-details {
  max-width: 900px;
  margin: auto;
  background: white;
  border-radius: 12px;
}

/* Store Logo */
.store-logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 2px solid #ddd;
}

/* Store Banner */
.store-banner img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

/* Category Navigation */
.category-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-x: auto;
  padding: 10px 0;
  white-space: nowrap;
}

/* Latest Items Grid */
.item-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 16px;
  margin-top: 10px;
}

/* QR Code Modal */
.modal.fade.show {
  background: rgba(0, 0, 0, 0.5);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .store-details {
    text-align: center;
  }
  .category-nav {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }
}
</style>
