<template>
  <div class="card">
    <div class="item-image" @click="showLightbox">
      <img :src="getImageUrl(itemimage)" alt="Image" class="img-fluid">
    </div>
    <div class="item-price">
      <p>{{ title }}</p>
      <p>{{ price }} $</p>
    </div>

   <!-- Lightbox Modal -->
   <div v-if="isLightboxVisible" class="lightbox-overlay" @click="closeLightbox">
      <div class="lightbox-content card shadow-lg p-3" @click.stop>
        <img :src="getImageUrl(itemimage)" alt="Image" class="img-fluid lightbox-image">
        
        <div class="card-body">
          <h5 class="card-title">{{ title }}</h5>
          <p class="card-text"><strong>Price:</strong> ${{ price }}</p>
          <p class="card-text">{{ description }}</p>

          <div class="d-flex flex-wrap gap-3 mt-3">
            <!-- Store Contact -->
            <a v-if="store?.storecontact" :href="`tel:${store.storecontact}`" class="btn btn-outline-primary d-flex align-items-center">
              <i class="fas fa-phone-alt me-2"></i> Call
            </a>

            <!-- Store Telegram -->
            <a v-if="store?.storetelegram" :href="store.storetelegram" target="_blank" class="btn btn-outline-info d-flex align-items-center">
              <i class="fab fa-telegram-plane me-2"></i> Telegram
            </a>

            <!-- Visit Store -->
            <router-link v-if="store?.storeurl" :to="`/store/${store.storeurl}`" class="btn btn-outline-success d-flex align-items-center">
              <i class="fas fa-store me-2"></i> Store
            </router-link>
          </div>
        </div>

        <!-- Close Button -->
        <button class="lightbox-close btn btn-danger" @click="closeLightbox">✖</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl } from '@/utils/imageHelper';
import axios from "axios";

export default {
  props: ["id", "price", "title", "category", "page", "description", "cover", "itemimage", "storeid"],
  data() {
    return {
      isLightboxVisible: false,
      store: null,  // Add store as null initially
    };
  },
  async mounted() {
    // Fetch store data when the component is mounted
    await this.fetchStoreData();
  },
  methods: {
    async fetchStoreData() {
      const storeId = this.storeid;
      try {
        const storeResponse = await axios.get(`/v1/stores/${storeId}`);
        this.store = storeResponse.data;
      } catch (error) {
        console.error('Error fetching store data:', error.response?.data || error.message);
      }
    },
    getImageUrl,
    showLightbox() {
      this.isLightboxVisible = true;
    },
    closeLightbox() {
      this.isLightboxVisible = false;
    },
  },
};
</script>

<style>
/* Image Container */
.image-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b3b3b3;
}

.image-container img {
  height: 100%;
  width: auto;
}

/* Lightbox Overlay */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Allows scrolling for long content */
  padding: 20px;
  box-sizing: border-box;
}

/* Lightbox Content */
.lightbox-content {
  position: relative;
  background: #fff;
  padding: 20px;
  max-width: 90%;
  max-height: 90vh;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

/* Scrollable content inside Lightbox */
.lightbox-content {
  overflow-y: auto; /* Enables vertical scrolling for long text */
}

/* Lightbox Image */
.lightbox-image {
  width: 100%;
  max-height: 60vh; /* Limits height but keeps it responsive */
  object-fit: contain;
  border-radius: 8px;
}

/* Caption */
.lightbox-caption {
  width: 100%;
  padding: 15px;
  color: #333;
  text-align: left;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  margin-top: 10px;
  overflow-y: auto; /* Ensures scrolling on long descriptions */
  max-height: 30vh; /* Prevents overflow on small screens */
}

/* Close Button */
.lightbox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff3b3b;
  border: none;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.lightbox-close:hover {
  background: #d32f2f;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .lightbox-content {
    max-width: 95%;
    max-height: 95vh;
    padding: 15px;
  }

  .lightbox-caption {
    font-size: 0.9rem;
    max-height: 35vh; /* Adjusts caption size for mobile */
  }

  .lightbox-image {
    max-height: 50vh; /* Ensures mobile-friendly scaling */
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

</style>