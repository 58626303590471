<template>
    <div class="card">
        <div class="store-image">
          <router-link :to="`/store/${storeurl}`"><img :src="getImageUrl(storebanner)" alt="Store Banner"/></router-link>
        </div>
        <div class="item-title">
            <p>{{storename}}</p>
        </div>
    </div>
</template>
<script>
import { getImageUrl } from '@/utils/imageHelper';

export default{
    props: ["id","storename", "storeaddress", "storelogo", "storebanner", "storeurl", "category"],
    methods: {
    getImageUrl,
  },
};
</script>
<style>
.image-container {
    height: 200px; /* Set the desired height for all images */
    overflow: hidden; /* Hide any overflow outside the container */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b3b3b3; /* Optional: background color for empty spaces */
  }
  
  .image-container img {
    height: 100%; /* Make the image fill the container height */
    width: auto; /* Maintain aspect ratio */
  }
  </style>