<template>
    <div class="container-fluid tm-mt-60">
        <div class="row mb-4">
            <h2 class="col-12 tm-text-primary">
                About Cloud Menu
            </h2>
        </div>
        <div class="row tm-mb-74 tm-row-1640">            
            <div class="col-lg-5 col-md-6 col-12 mb-3">
                <img src="@/assets/img/about.jpg" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-7 col-md-6 col-12">
                <div class="tm-about-img-text">
                    <h3>Looking for Personalized for your business?</h3> 
                    <p>
                        We offer free digital menu platform for traders, without any hidden fees or complicated contracts. Our goal is to provide a straightforward and cost-effective solution that meets the needs of your business, allowing you to focus on delivering exceptional digital experiences to your customers.</p>
                </div>                
            </div>
        </div>
        <div class="row tm-mb-50">
            <div class="col-md-6 col-12">
                <div class="tm-about-2-col">
                    <h2 class="tm-text-primary mb-4">
                        Using QR code as menu
                    </h2>
                    <p class="mb-4">
                        Using QR codes as menus offers several advantages. Firstly, it eliminates the need for physical menus, reducing the risk of spreading germs or viruses through contact. Additionally, QR codes allow for easy and quick access to menu options, providing a seamless and convenient dining experience for customers.
                    </p>
                </div>                
            </div> 
            <div class="col-md-6 col-12">
                <div class="tm-about-2-col">
                    <h2 class="tm-text-primary mb-4">
                        A better admin control
                    </h2>
                    <p class="mb-4">
                        Having a better admin control over the QR code menu system provides store owners with greater flexibility and convenience. You can easily update and modify your menus in real-time
                    </p>
                    <p>
                        Manage stores information: Easily update your store poster, contact and promotion.
                        Manage products categorize: Manage category for each product that allows customer to quickly navigate through the options and find what they are looking for.
                        Manage products: Our platform allows you to easily manage your product and make changes on the go. Stay in control of your menu, no matter where you are. 
                    </p>
                </div>                
            </div>     
        </div> <!-- row -->
        <div class="row tm-mb-50">
            <div class="col-md-4 col-12">
                <div class="tm-about-3-col">
                    <div class="tm-about-icon-container mb-5">
                        <i class="fas fa-desktop fa-3x tm-text-primary"></i>
                    </div>                
                    <h2 class="tm-text-primary mb-4">Mobile Friendly</h2>
                    <p class="mb-4">With just a scan, customers can easily access the menu on their smartphones without the need for physical menus or waiting for a server to bring one. This eliminates the hassle of handling paper menus and allows for a seamless browsing experience, enhancing customer satisfaction. </p>
                </div>                
            </div>
            <div class="col-md-4 col-12">
                <div class="tm-about-3-col">
                    <div class="tm-about-icon-container mb-5">
                        <i class="fas fa-mobile-alt fa-3x tm-text-primary"></i>
                    </div>                
                    <h2 class="tm-text-primary mb-4">Faster Speed</h2>
                    <p class="tm-mb-50">Ability to provide a fast and efficient dining experience. With just a quick scan of the QR code, customers can access the menu instantly on their smartphones, eliminating the need for physical menus and reducing wait times.</p>                
                </div>                
            </div>
            <div class="col-md-4 col-12">
                <div class="tm-about-3-col">
                    <div class="tm-about-icon-container mb-5">
                        <i class="fas fa-photo-video fa-3x tm-text-primary"></i>
                    </div>                
                    <h2 class="tm-text-primary mb-4">Easy to Maintains</h2>
                    <p class="mb-4">Can be updated quickly and effortlessly, ensuring that the information provided to customers is always accurate and up-to-date.</p>
                </div>                
            </div>
        </div>
    </div> <!-- container-fluid, tm-container-content -->
</template>