<template>
  <Terms/>
</template>

<script>
import Terms from '@/components/Terms.vue';

export default {
  name: 'App',
  components: {
    Terms
  }
}
</script>
