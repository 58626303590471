<template>
<!--     <div class="tm-hero d-flex justify-content-center align-items-center">
        <form class="d-flex tm-search-form" @submit.prevent="performSearch">
            <input
                class="form-control tm-search-input"
                type="search"
                placeholder="Search Item"
                aria-label="Search"
                v-model="searchQuery"
            />
            <button class="btn btn-outline-success tm-search-btn" type="submit">
                <i class="fas fa-search"></i>
            </button>
        </form>
    </div> -->
    <div class="main">
        <section>
            <div class="category">
                <h2>New Items</h2>
            </div>
            <div class="cards">
                <ItemCard v-for="(item, index) in items"
                    :key="index"
                    :id="item._id"
                    :title="item.title"
                    :description="item.description"
                    :price="item.price"
                    :storeid="item.storeid"  
                    :itemimage="item.filename"
                    :category="item.category">
            </ItemCard>
            </div>
        </section>
        <section><p></p></section>
        <div class="col-12 d-flex justify-content-between align-items-center tm-paging-col"><a href="#" class="btn btn-primary tm-btn-prev mb-2 disabled">Previous</a>
      <div class="tm-paging d-flex"><a href="#" class="active tm-paging-link">1</a> <a href="#" class="tm-paging-link">2</a> <a href="#" class="tm-paging-link">3</a> <a href="#" class="tm-paging-link">4</a></div>
      <a href="#" class="btn btn-primary tm-btn-next">Next Page</a></div>
</div>

</template>

<script>
import ItemCard from '@/components/ItemCard.vue';
import axios from "axios";

export default {
    components: { ItemCard },
    data() {
        return {
            items: [], // Stores the items displayed
            allItems: [], // Stores all items fetched by default
            searchQuery: '', // Search input
        };
    },
    async created() {
        await this.fetchAllItems(); // Fetch all stores on page load
    },
    methods: {
        async fetchAllItems() {
            try {
                const response = await axios.get('/v1/items?limit=12&page=2'); // Fetch all items
                this.allItems = response.data.docs; // Save all items
                this.items = response.data.docs; // Display all items by default
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        },
        performSearch() {
            if (!this.searchQuery) {
                // If the search query is empty, show all items
                this.items = this.allItems.docs;
            } else {
                const query = this.searchQuery.toLowerCase();
                this.items = this.allItems.filter(item =>
                    item.title.toLowerCase().includes(query) ||
                    item.description.toLowerCase().includes(query)
                );
            }
        },
    },
};
</script>

