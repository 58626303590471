<template>
    <div class="main">
          <section>
                <div class="category">
                    <h2>New Store</h2>
                </div>
                <div class="cards">
                    <StoreCard v-for="(store, index) in stores.slice(0, 10)"
                        :key="index"
                        :id="store._id"
                        :storename="store.storename"
                        :storeaddress="store.storeaddress"
                        :storebanner="store.storebanner"
                        :storeurl="store.storeurl"
                        :category="store.category">
                    </StoreCard>
                </div>
                <div class="category">
                    <h2>Lastest Items</h2>
                </div>
                <div class="cards">
                    <ItemCard v-for="(item, index) in items.slice(0, 10)"
                        :key="index"
                        :id="item._id"
                        :title="item.title"
                        :description="item.description"
                        :price="item.price"
                        :storeid="item.storeid"  
                        :itemimage="item.filename"
                        :category="item.category">
                </ItemCard>
                </div>
            </section>
            
        <a href="#" id="scroll" style="display: none;"><span></span></a>
    </div>
    </template>
    <script>
    import StoreCard from '@/components/StoreCard.vue';
    import ItemCard from '@/components/ItemCard.vue';
    import axios from "axios";
    
    export default({
        components: {
            StoreCard,
            ItemCard,
        },
        data() {
            return{stores:[],items:[]
            };
        },
        async created(){
            const storeurl= "/v1/stores";
            const itemurl= "/v1/items";
            try {
                //promise and async
                const store_response = await axios.get(storeurl);
                const stores = store_response.data.docs.slice(0, 12);
                this.stores = stores;
                const item_response = await axios.get(itemurl);
                const items = item_response.data.docs.slice(0, 12);
                this.items = items;
            } 
            catch (error) 
            {
                console.log(error);
            }
        }
        
    });
    
    </script>